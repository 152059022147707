<template>
  <span>
    <el-breadcrumb separator="/" class="custom-breadcrumb">
      <el-breadcrumb-item v-if="url!=='/home'" :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <template v-for="item in list">
        <el-breadcrumb-item v-if="url===item.path||!item.meta.isBreadcrumb">{{item.meta.title}}</el-breadcrumb-item>
        <el-breadcrumb-item v-else :to="{ path: item.path }">{{item.meta.title}}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </span>
</template>

<script>
  export default {
    data() {
      return {
        list:[],
        url:''
      }
    },
    watch: {
      $route() {
        this.getBreadcrumb()
      }
    },
    created() {
      this.getBreadcrumb()
    },
    methods: {
      getBreadcrumb() {
        let matched = this.$route.matched.filter(item => item.path)
        this.url=this.$route.path
        this.list=matched
      }
    }
  }
</script>

<style scoped>
  .custom-breadcrumb {
    line-height: inherit;
    margin: 0 -20px;
  }
</style>
