<template>
	<div class="pd10">
		<el-card class="custom-card">
			<div slot="header" class="clearfix">
				<span class="border-l relative pdL10">{{$route.meta.title}}</span>
			</div>
			<el-input v-model="searchForm.versionAlias" placeholder="版本别名" class="mgB10 custom-form-input"></el-input>
			<el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">
				搜索
			</el-button>
			<el-button type="success" @click="add" plain class="mgL10 mgB10">添加
			</el-button>
			<!-- 表格 -->
			<el-table :data="tableData" border v-loading="loading">
				<el-table-column type="index" header-align="center" align="center" label="序号" width="50">
				</el-table-column>
				<el-table-column prop="downUrl" :show-overflow-tooltip="true" header-align="center" align="center"
					label="下载地址">
				</el-table-column>
				<el-table-column prop="storageFile" :show-overflow-tooltip="true" header-align="center" align="center"
					label="储存位置"></el-table-column>
				<el-table-column prop="version" :show-overflow-tooltip="true" header-align="center" align="center"
					label="版本号">
				</el-table-column>
				<el-table-column prop="versionAlias" :show-overflow-tooltip="true" header-align="center" align="center"
					label="版本别名"></el-table-column>
				<el-table-column prop="introduce" :show-overflow-tooltip="true" header-align="center" align="center"
					label="版本介绍"></el-table-column>
				<el-table-column label="操作" width="100" header-align="center" align="center" fixed="right">
					<template slot-scope="scope">
						<el-button @click.native.prevent="delRow(scope.row)" type="danger" size="mini"
							class="custom-table-btn">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach">
			</table-page>
		</el-card>
		<el-dialog class="custom-dialog" title="版本发布" :visible.sync="dialogVisible" width="520px" :append-to-body="true"
			@close="closeAlert">
			<el-form ref="form" :rules="formRules" :model="form" label-width="80px">
				<el-form-item label="版本号" prop="version" class="custom-form-item">
					<el-input-number v-model="form.version" controls-position="right" :min="1" style="width: 100%;">
					</el-input-number>
				</el-form-item>
				<el-form-item label="版本别名" prop="versionAlias" class="custom-form-item">
					<el-input v-model="form.versionAlias" placeholder="请输入版本别名"></el-input>
				</el-form-item>
				<el-form-item label="版本类型" prop="editionType" class="custom-form-item">
					<el-radio-group v-model="form.editionType">
						<el-radio label="loca">单机版</el-radio>
						<el-radio label="network">网络版</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="文件上传" prop="downUrl" class="custom-form-item">
					<el-upload ref="upload" class="upload-demo" :action="action" :data="{type:form.editionType}" :headers="headers" :multiple="false"
						:on-remove="handleRemove" :on-error="onError" :on-success="onSuccess" :limit="1"
						:on-exceed="handleExceed" :file-list="fileList">
						<el-button type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">只能上传jar文件，文件不宜过大</div>
					</el-upload>
				</el-form-item>

				<el-form-item label="版本介绍" prop="introduce" class="custom-form-item">
					<el-input type="textarea" v-model="form.introduce" placeholder="[],包裹描述"></el-input>
				</el-form-item>
			</el-form>
			<div class="bottom-btn">
				<el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">确 定
				</el-button>
				<el-button @click="closeAlert">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				action: this.$api.BaseUrl + this.$api.editionUpload,
				headers: {
					Authorization: "Bearer " + this.$cookies.get(this.$cache.TOKEN)
				},
				searchForm: {
					versionAlias: "",
					page: 1,
					limit: 10,
				},
				fileList: [],
				loading: false,
				tableData: [],
				total: 0,
				dialogVisible: false,
				loadingAlert: false,
				form: {
					editionType: 'network',
					version: "",
					versionAlias: '',
					introduce: '',
					downUrl: 'a',
					storageFile: 'a'
				},
				formRules: {
					editionType: [{
						required: true,
						message: '不可为空',
						trigger: 'blur'
					}],
					version: [{
						required: true,
						message: '不可为空',
						trigger: 'blur'
					}],
					downUrl: [{
						required: true,
						message: '不可为空',
						trigger: 'blur'
					}],
					versionAlias: [{
						required: true,
						message: '不可为空',
						trigger: 'blur'
					}],
					introduce: [{
						required: true,
						message: '不可为空',
						trigger: 'blur'
					}],
				}
			}
		},
		mounted() {
			this.search();
		},
		methods: {
			onSuccess: function(response) {
				if (response.code !== '200' && response.code !== '0') {
					if (response.errMsg) {
						this.$message({
							type: 'error',
							message: response.errMsg
						});
						this.$refs.upload.clearFiles()
					}
				} else {
					this.form.downUrl = response.data.downUrl
					this.form.storageFile = response.data.storageFile
				}
			},
			onError: function() {
				this.$refs.upload.clearFiles()
				this.$message({
					type: 'error',
					message: '上传失败!'
				});
			},
			handleRemove() {
				this.form.downUrl = ""
				this.form.storageFile = ""
				this.$refs.upload.clearFiles()
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			//添加
			add() {
				this.dialogVisible = true;
			},
			//关闭
			closeAlert() {
				this.dialogVisible = false;
				this.$refs["form"].resetFields();
				this.loadingAlert = false;
			},
			//表单提交
			onSubmit: function(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.addUser()
					} else {
						return false;
					}
				});
			},
			//添加信息
			addUser() {
				this.loadingAlert = true
				let params = JSON.parse(JSON.stringify(this.form))
				// params["userRole"] = params.userRole[params.userRole.length - 1]
				this.$axios.Post(this.$api.editionRelease, params).then(() => {
					this.$message({
						type: 'success',
						message: '添加成功!'
					});
					this.closeAlert();
					this.getData(this.searchForm.page, this.searchForm.limit)
				}).catch((err) => {
					if (err.errMsg) {
						this.$message({
							type: 'error',
							message: err.errMsg
						});
					}
					this.loadingAlert = false
				})
			},
			//获取数据
			getData(page, limit) {
				this.loading = true;
				this.$axios.Get(this.$api.editionList, {
					versionAlias: this.searchForm.versionAlias,
					liangName: this.searchForm.liangName,
					page: page,
					limit: limit
				}).then(res => {
					this.searchForm.limit = limit
					this.searchForm.page = page
					this.tableData = res.data;
					this.total = res.count
					this.loading = false;
				}).catch(() => {
					this.loading = false;
				})
			},
			//搜索
			search() {
				this.getData(1, this.searchForm.limit)
			},
			//分页
			feach(e) {
				this.getData(e.page, e.limit)
			},
			//删除表格数据
			delRow: function(row) {
				this.$confirm('确认要删除该条信息吗,删除版本会连带着删除版本文件?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.Post(this.$api.editionDel, {
						"id": row.id
					}).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getData(this.searchForm.page, this.searchForm.limit)
					}).catch((err) => {
						if (err.errMsg) {
							this.$message({
								type: 'error',
								message: err.errMsg
							});
						}
					})
				}).catch(() => {});
			},
		},
		components: {
			"table-page": () => import('@/components/TablePage')
		}
	}
</script>

<style scoped lang="less">
</style>
