<template>
  <el-header class="custom-header">
    <el-menu class="custom-header-menu custom-header-menu-l" mode="horizontal" @select="handleHeaderSelect">
      <el-menu-item index="FOLD_MENU">
        <i :class="['custom-header-icon',collapseMenu?'el-icon-s-unfold':'el-icon-s-fold']"></i>
      </el-menu-item>
      <el-menu-item>
        <Breadcrumb />
      </el-menu-item>
      <el-menu-item v-auth="'liang_change'">
        <router-link :to="{path:'/grain/change'}"><span>{{liangName()}}</span></router-link>
      </el-menu-item>
    </el-menu>
    <el-menu class="custom-header-menu r0 mgR25" mode="horizontal" @select="handleHeaderSelect">
      <el-menu-item>
        <el-dropdown @command="commandDropdown" size="small">
          <span class="el-dropdown-link">
            <!-- <el-avatar size="small" src="../../assets/logo.ico"></el-avatar> -->
            <el-avatar size="small" :src="require('../../assets/logo.png')"></el-avatar>
            <span class="mgL8">{{userName}}</span>
            <i class="el-icon-caret-bottom custom-header-img"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="OWEN_USER">基本资料</el-dropdown-item>
            <el-dropdown-item command="UPDATE_PASS">修改密码</el-dropdown-item>
            <el-dropdown-item divided command="LOGIN_OUT">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu-item>
    </el-menu>
  </el-header>
</template>

<script>
  import {
    getTokenInfo
  } from '@/common/jwt.js'
  import Breadcrumb from '@/components/Layout/Breadcrumb.vue'
  export default {
    components: {
      Breadcrumb
    },
    props: {
      collapseMenu: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        userName: ''
      }
    },
    created() {
      this.userName = getTokenInfo(this.$cookies.get(this.$cache.TOKEN), "username")
    },
    methods: {
      //header菜单点击事件
      handleHeaderSelect: function(e) {
        switch (e) {
          case "FOLD_MENU":
            this.$emit('update:collapseMenu', !this.collapseMenu);
            break;
        }
      },
      //下拉菜单操作
      commandDropdown: function(e) {
        switch (e) {
          case "LOGIN_OUT":
            this.$store.dispatch('LogOut').then(() => {
              location.reload() // 为了重新实例化vue-router对象 避免bug
            })
            break;
          case "OWEN_USER":
            this.$router.push({
              path: "/user"
            }).catch(() => {

            })
            break;
          case "UPDATE_PASS":
            this.$router.push({
              path: "/pass"
            }).catch(() => {

            })
            break;
        }
      },
      //粮仓信息
      liangName() {
        return this.$cookies.get(this.$cache.LIANG_NAME)
      }
    }
  }
</script>

<style scoped>
  /* 头部 */
  .custom-header {
    background-color: #FFFFFF;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: solid 1px #F2F6FC;
  }

  .custom-header .el-menu-item:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    pointer-events: none;
    background-color: #ee0a24;
    background-color: var(--color-primary);
    height: 2px;
    width: 0;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
  }

  .custom-header .el-menu-item:hover:after {
    width: 100%;
  }



  /* 头部菜单 */
  .custom-header-menu {
    border-bottom: none;
    position: absolute;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
  }

  .custom-header-icon {
    font-size: 14px !important;
    color: #001529 !important;
  }

  .custom-header-menu>.el-menu-item.is-active,
  .custom-header-menu {
    border-bottom: none !important;
  }

  .custom-header-img {
    position: absolute;
    line-height: 55px;
  }

  .el-dropdown-link:hover .custom-header-img {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
</style>
