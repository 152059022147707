export function creatSocket(id) {
  if ('WebSocket' in window) {
    window.ws = new WebSocket(process.env.VUE_APP_WS_API+'/'+id);
    window.ws.onopen = (() => {
      console.log("socket连接成功")
    })
  } else {
    alert('当前浏览器 Not support websocket')
  }
}

export function closeSocket() {
  if (window.ws) {
    window.ws.close()
    window.ws.onclose = (() => {
      console.log(console.log("socket已经关闭"))
    })
  }
}
