<template>
  <div class="home-content">
    <last-testing></last-testing>
  </div>
</template>

<script>
  export default {
    name: "home",
    components: {
      "last-testing": () => import('@/components/LastTesting')
    },
    data() {
      return {}
    },
    mounted() {},
    methods: {

    }
  }
</script>

<style scoped lang="less">
  .home-content {
    padding: 5px;
  }
</style>
