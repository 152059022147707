<template>
  <div>
    <span v-for="item in menuList" :key="item.path">
      <el-submenu v-if="item.children&&item.children.length>0" :index="item.path">
        <template slot="title">
          <i :class="item.meta.icon"></i>
          <span slot="title">{{item.meta.title}}</span>
        </template>
        <GenerateMenu :menuList="item.children"></GenerateMenu>
      </el-submenu>
      <el-menu-item v-else-if="!item.hide" :index="item.path">
        <i v-if="item.meta.icon" :class="item.meta.icon"></i>
        <span slot="title">{{item.meta.title}}</span>
      </el-menu-item>
    </span>
  </div>
</template>

<script>
  import GenerateMenu from './GenerateMenu.vue';
  export default {
    name: 'GenerateMenu',
    props: {
      menuList: {
        type: Array,
        default: () => []
      },
      parentName: {
        type: String,
        default: ""
      }
    },
    data() {
      return {}
    },
    components: {
      GenerateMenu
    },
  }
</script>

<style scoped="scoped">
  .el-submenu .el-menu-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

 .el-submenu__title .testing_web_iconfont,
  .el-menu-item .testing_web_iconfont {
    font-weight: 600
  }

 /* .el-submenu__title testing_web_iconfont+span,
  .el-menu-item .testing_web_iconfont+span {
    margin-left: 8px;
  } */
</style>
