<template>
  <el-card class="custom-card mgAll10">
    <div slot="header" class="clearfix">
      <span class="border-l relative pdL10">{{$route.meta.title}}</span>
    </div>
    <el-form :model="form" ref="form" :rules="rules" label-width="90px">
      <el-row>
        <el-divider content-position="left">基础信息设置</el-divider>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="设备号" prop="deviceNo" class="custom-form-item">
            <el-input v-model="form.deviceNo" placeholder="请输入设备号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="分机号" prop="tempMainNo" class="custom-form-item">
            <el-input v-model="form.tempMainNo" placeholder="请输入外温外湿分机号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="是否有线" prop="tempMainWired" class="custom-form-item">
            <el-radio-group v-model="form.tempMainWired">
              <el-radio :label="false">无线</el-radio>
              <el-radio :label="true">有线</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-divider content-position="left">打印项设置设置</el-divider>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="仓温仓湿" prop="printStorehouse" class="custom-form-item">
            <el-radio-group v-model="form.printStorehouse">
              <el-radio :label="false">打印不显示</el-radio>
              <el-radio :label="true">打印显示</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="气温气湿" prop="printExternal" class="custom-form-item">
            <el-radio-group v-model="form.printExternal">
              <el-radio :label="false">打印不显示</el-radio>
              <el-radio :label="true">打印显示</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="仓房档案" prop="printArchives" class="custom-form-item">
            <el-radio-group v-model="form.printArchives">
              <el-radio :label="false">打印不显示</el-radio>
              <el-radio :label="true">打印显示</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-divider content-position="left">数据保存方式</el-divider>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="保存方式" prop="testingCover" class="custom-form-item">
            <el-radio-group v-model="form.testingCover">
              <el-radio :label="false">每次测量都保存</el-radio>
              <el-radio :label="true">每天每仓仅保留最后一次测量数据</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div class="center mgB15">
          <el-button type="primary" @click="onSubmit('form')" :loading="loadingAlert" :disabled="loadingAlert">修 改
          </el-button>
          <el-button @click="search">刷 新</el-button>
        </div>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
  export default {
    data() {
      return {
        loadingAlert: false,
        form: {
          deviceNo:"",
          tempMainWired: false,
          tempMainNo: "",
          printStorehouse: false,
          printExternal: false,
          printArchives: false,
          testingCover: true
        },
        rules: {
          deviceNo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          tempMainWired: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          tempMainNo: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          printStorehouse: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          printExternal: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          printArchives: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          testingCover: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        }
      }
    },
    created() {
      this.search()
    },
    methods: {
      search() {
        this.$axios.Get(this.$api.cfParamSee, {}).then(res => {
          this.form=res.data
        })
      },
      //表单提交
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.edit();
          } else {
            return false;
          }
        });
      },
      //编辑信息
      edit() {
        this.loadingAlert = true
        this.$axios.Post(this.$api.cfParamUpdate, this.form).then(() => {
          this.loadingAlert=false
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
        }).catch((err) => {
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
          this.loadingAlert = false
        })
      },
    }
  }
</script>

<style>
</style>
