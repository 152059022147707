<template>
  <el-container :class="['content',collapseMenu?'left-menu-collaps-':'left-menu-collaps']">
    <el-aside class="custom-aside">
      <div class="custom-aside-header">
        <img v-if="collapseMenu" src="../assets/logo.png" />
        <span v-else> 粮情测控系统</span>
      </div>
      <!-- 左侧菜单 -->
      <el-menu class="custom-aside-menu" text-color="#FFFFFF" background-color="#304156" :unique-opened="true"
        :collapse-transition="false" :collapse="collapseMenu" router :default-active="$route.path">
        <GenerateMenu :menuList="menuList"></GenerateMenu>
      </el-menu>
      <!-- 左侧菜单 -->
    </el-aside>
    <el-container>
      <Head :collapseMenu.sync="collapseMenu" />
      <el-main class="custom-main">
        <div :style="{left:collapseMenu?'64px':'200px'}" class="custom-main-body">
          <div class="custom-main-page">
            <!-- <transition name="el-fade-in"> -->
            <keep-alive>
              <router-view v-if="!$route.meta.noCache"></router-view>
            </keep-alive>
            <!-- </transition> -->
            <!-- <transition name="el-fade-in"> -->
            <router-view v-if="$route.meta.noCache" key="not-keep-alive"></router-view>
            <!-- </transition> -->
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import GenerateMenu from '@/components/Layout/GenerateMenu.vue';
  import Head from '@/components/Layout/Head.vue';
  import {
    creatSocket
  } from "@/common/webSocket.js"
  export default {
    name: "index",
    data() {
      return {
        collapseMenu: false,
        menuList: this.$store.getters.addRouters
      }
    },
    created() {
      let collapseMenu = this.$cookies.get(this.$cache.COLLAPSE_MENU)
      this.collapseMenu = collapseMenu === 'true'
      this.getLiangConfig()
    },
    watch: {
      collapseMenu(e) {
        this.$cookies.set(this.$cache.COLLAPSE_MENU, e);
      }
    },
    methods: {
      //切换tab
      changeTab: function(e) {
        console.log(e)
      },
      //获取粮库配置
      getLiangConfig() {
        this.$axios.Get(this.$api.cfParamSee, {}).then(res => {
          if (res.data.deviceNo) creatSocket(res.data.deviceNo)
          console.log(window.ws)
        })
      }
    },
    components: {
      GenerateMenu,
      Head
    }
  }
</script>
<style scoped lang="scss">
  //主界面
  .content {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  //左侧
  .custom-aside {
    left: 0;
    overflow: hidden;
    background-color: #FFFFFF;
    box-shadow: 5px 1px 5px #a7a7a7;
    background-color: #304156;
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 1001;
    position: fixed;
    bottom: 0;
  }

  //左侧头部
  .custom-aside-header {
    width: 100%;
    text-align: center;
    color: #FFFFFF;

    img {
      width: 40px;
      margin-top: 5px;
    }
  }

  .custom-aside .el-menu {
    border-right: none !important;
  }

  //左侧菜单
  .custom-aside-menu {
    padding: 5px 0;
    flex: 1;
    border-right: none;
    overflow-y: auto;
  }

  .custom-aside-menu::-webkit-scrollbar {
    display: none;
  }


  //主体
  .custom-main {
    padding: 0 !important;
  }

  .custom-main-body {
    position: fixed;
    background-color: #F2F6FC;
    left: 200px;
    right: 0;
    overflow: hidden;
    top: 51px;
    bottom: 0;
  }

  .custom-main-page {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  //公用
  .custom-aside,
  .custom-main-body,
  .el-menu-item,
  .el-submenu__title,
  .custom-aside-menu {
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
  }
</style>
