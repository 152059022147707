<template>
  <div>
    <el-form label-width="70px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="仓房名称" class="custom-form-item">
            <el-input readonly :value="tempInfo.houseName||'-'"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="测温时间" class="custom-form-item">
            <el-input readonly :value="tempInfo.testingTime||'-'"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="仓温" class="custom-form-item">
            <el-input readonly :value="(tempInfo.storehouseTemp)+'℃'"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓湿" class="custom-form-item">
            <el-input readonly :value="(tempInfo.storehouseDampness)+'%'"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="气温" class="custom-form-item">
            <el-input readonly :value="(tempInfo.externalTemp)+'℃'">s</el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="气湿" class="custom-form-item">
            <el-input readonly :value="(tempInfo.externalDampness)+'%'"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="tempInfo.maxTemp||tempInfo.maxTemp===0||tempInfo.minTemp||tempInfo.minTemp===0">
        <el-col :span="12" v-if="tempInfo.maxTemp||tempInfo.maxTemp===0">
          <el-form-item label="最高温" class="custom-form-item">
            <el-input readonly :value="tempInfo.maxTemp+'℃'"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="tempInfo.minTemp||tempInfo.minTemp===0">
          <el-form-item label="最低温" class="custom-form-item">
            <el-input readonly :value="tempInfo.minTemp+'℃'"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="tempInfo.avgTemp||tempInfo.avgTemp===0">
        <el-col :span="12">
          <el-form-item label="平均温" class="custom-form-item">
            <el-input readonly :value="tempInfo.avgTemp+'℃'"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  export default {
    props: {
      tempInfo: {
        type: Object,
        default: () => {
          return {}
        }
      }
    }
  }
</script>

<style>
</style>
