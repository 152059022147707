<template>
  <el-card class="custom-card">
    <div slot="header" class="clearfix">
      <span class="border-l relative pdL10">串口接受配置</span>
      <el-button class="right" type="text" @click="onSubmit('form')">修改</el-button>
    </div>
    <el-form ref="form" :rules="rules" :model="form" label-width="80px">
      <el-form-item label="默认端口" prop="port" class="custom-form-item">
        <el-input v-model="form.port" type="number" placeholder="默认端口"></el-input>
      </el-form-item>
      <el-form-item label="超时时间" prop="timeOut" class="custom-form-item">
        <el-input-number style="width: 100%;" v-model="form.timeOut" placeholder="超时时间/秒" controls-position="right"
          :min="1"></el-input-number>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
  export default {
    props: ["port", "timeOut"],
    watch:{
      port(val){
        this.form.port=val
      },
      timeOut(val){
        this.form.timeOut=val
      }
    },
    inject: ['search'],
    data() {
      return {
        loading: false,
        form: {
          timeOut: "",
          port: ""
        },
        rules: {
          port: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          timeOut: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
        }
      }
    },
    methods: {
      //修改提交
      onSubmit(formName) {
        if (this.loading) return;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.$axios.Post(this.$api.configureTestingConfig, this.form).then(() => {
              this.loading = false;
              this.$message({
                type: 'success',
                message: "修改成功"
              });
              // this.search();
            }).catch((err) => {
              this.loading = false;
              if (err.errMsg) {
                this.$message({
                  type: 'error',
                  message: err.errMsg
                });
              }
            })
          } else {
            return false;
          }
        });
      }
    },
    created() {

    }
  }
</script>

<style>
</style>
