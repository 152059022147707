<template>
  <div class="error-content">
    <img :src="errorBg" />
    <router-link :to="{path:'/'}">
      <el-button type="primary" class="mgT20" icon="el-icon-refresh-right">返回首页</el-button>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "error",
    data() {
      return {
        errorBg: require("../assets/img/404.png")
      }
    },
    mounted() {
      // switch (this.$route.query.code) {
      //   case "500":
      //     this.errorBg = require("../assets/img/500.png")
      //     break;
      //   case "401":
      //     this.errorBg = require("../assets/img/401.png")
      //     break;
      //   case "403":
      //     this.errorBg = require("../assets/img/403.png")
      //     break;
      //   case "404":
      //     this.errorBg = require("../assets/img/404.png")
      //     break;
      // }
    }
  }
</script>

<style scoped lang="less">
  .error-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      // width: 500px;
      height: 500px;
    }
  }
</style>
