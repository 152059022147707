<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="原密码" prop="pwd" class="custom-form-item">
              <el-input v-model="form.pwd" type="password" placeholder="原密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="新密码" prop="modifyPwd" class="custom-form-item">
              <el-input v-model="form.modifyPwd" type="password" placeholder="新密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="确认密码" prop="newPwd" class="custom-form-item">
              <el-input v-model="form.newPwd" type="password" placeholder="确认密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row >
          <el-col :span="24"> -->
        <div class="center">
          <el-button type="primary" @click="onSubmit('form')" :loading="loading" :disabled="loading">修 改
          </el-button>
        </div>
        <!--  </el-col>
        </el-row> -->
      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      var than = this;
      return {
        loading: false,
        form: {
          pwd: "",
          modifyPwd: "",
          newPwd: ''
        },
        rules: {
          pwd: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          modifyPwd: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          newPwd: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (than.form.modifyPwd !== value) {
                callback(new Error('两次输入的密码不一致!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }],
        }
      }
    },
    mounted() {

    },
    methods: {
      //修改提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$confirm('确认要修改密码吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.userModifyPwd()
            }).catch(() => {});

          } else {
            return false;
          }
        });
      },
      //修改密码
      userModifyPwd() {
        this.loading = true
        this.$axios.Post(this.$api.userModifyPwd, this.form).then(() => {
          this.loading = false
          this.$message({
            type: 'success',
            message: "修改成功"
          });
          this.$store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }).catch((err) => {
          this.loading = false
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
        })
      }
    }
  }
</script>

<style scoped lang="less">
</style>
