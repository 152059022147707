<template>
  <div>
    <el-card class="custom-card mgAll10">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
        <el-button class="right" type="text" @click="dialog=true">进度</el-button>
      </div>
      <div style="position: relative;">
        <el-button type="primary" @click="choiceAccept" :disabled="disabled" plain>
          选择接收
        </el-button>
        <el-button type="primary" @click="allAccept" :disabled="disabled" plain class="mgL10">
          全部接收
        </el-button>
        <div style="position: absolute;right: 0; top: 0;left: 180px; padding: 4px 0; bottom: 0;">
          <el-progress :text-inside="true" :stroke-width="20" :percentage="percentage"></el-progress>
        </div>
      </div>
    </el-card>
    <div class="content">
      <last-testing :checkbox="true" ref="lastTesting"></last-testing>
    </div>
    <el-drawer :with-header="false" :visible.sync="dialog" direction="rtl" ref="drawer" :modal="false"
      custom-class="custom-drawer-class">
      <div>
        <el-card class="custom-card" shadow="never" style="border: none;">
          <div slot="header" class="clearfix">
            <span class="border-l relative pdL10">进度详情</span>
            <el-button class="right" type="text" @click="dialog=false">关闭</el-button>
          </div>
          <testing-text :list="socketText"></testing-text>
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  export default {
    name: "home",
    components: {
      "last-testing": () => import('@/components/LastTesting'),
      "testing-text": () => import('@/components/TestingText/Index')
    },
    data() {
      return {
        disabled: false,
        dialog: false,
        percentage: 0,
        socketText: [],
        testingSize: 0,
        testingComplete: 0
      }
    },
    methods: {
      addSocket() {
        var than = this;
        var timer = setInterval(() => {
          if (window.ws) {
            window.ws.onmessage = function(e) {
              let data = JSON.parse(e.data);
              if (data.testingType === 0) {
                than.socketText.push(data)
                if (data.type === 7 || data.type === 4) {
                  than.disabled = false;
                  than.percentage = 100
                  than.$refs.lastTesting.lastTesting()
                  than.$refs.lastTesting.clearChecked()
                } else if (data.type === 0) {
                  let avg = (100 / than.testingSize).toFixed(3);
                  let percentage = Math.round(than.testingComplete * avg + avg * 1)
                  than.percentage = percentage > 100 ? 99 : percentage
                  than.testingComplete = than.testingComplete + 1;
                } else {
                  let avg = (100 / than.testingSize).toFixed(3);
                  let percentage = than.percentage + 5;
                  let nextPercentage = than.testingComplete * avg + avg * 1
                  if (percentage < nextPercentage && percentage < 100) {
                    than.percentage = Math.round(percentage);
                  }
                }
              }
              console.log(e)
            }
            clearInterval(timer)
          }
        }, 1000)
      },
      choiceAccept() {
        this.testingTemp(this.$refs.lastTesting.getChecked())
        // this.$refs.lastTesting.clearChecked()
      },
      allAccept() {
        this.testingTemp(this.$refs.lastTesting.getAllChecked())
        // this.$refs.lastTesting.clearChecked()
      },
      testingTemp(ids) {
        if (!window.ws) {
          this.$message({
            type: 'error',
            message: "Socket未连接"
          });
          return;
        }
        if (ids.length > 0) {
          this.socketText = [];
          this.disabled = true;
          this.testingSize = ids.length;
          this.percentage = 0
          this.testingComplete = 0
          this.$axios.Post(this.$api.testingTemp, {
            "ids[]": ids.toString()
          }).then(() => {}).catch((err) => {
            this.disabled = false;
            this.testingSize = 0;
            if (err.errMsg) {
              this.$message({
                type: 'error',
                message: err.errMsg
              });
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: "请选择要检测的仓房"
          });
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      console.log(to, from)
      next(vm => {
        vm.addSocket()
      })
    },
    beforeRouteLeave(to, from, next) {
      console.log(to, from)
      if (window.ws) window.ws.onmessage = null;
      next()
    }
  }
</script>

<style scoped lang="less">
  .content {
    padding: 5px;
    margin-top: -10px;
  }

  /deep/ .custom-drawer-class {
    top: var(--layout-header-height);
    height: auto;
    overflow: auto;
  }

  // .testing-bottom {
  // position: fixed;
  // right: 50px;
  // width: 200px;
  // bottom: 80px;
  // background-color: #fff;
  // z-index: 10;
  // }
</style>
